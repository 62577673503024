import { Action, createReducer, on } from '@ngrx/store';
import { getInitialParamsState } from '../../../../../utils/getInitialStateFromLocalStorage';
import { IProductionStandsTypesParams } from '../../../models/productionStandsTypesParams';
import * as ProductionStandsTypesActions from './production-stands-types.actions';

export interface ProductionStandsTypeState {
  queryParams: IProductionStandsTypesParams;
  loading: boolean;
  error: boolean;
}

const initialState: ProductionStandsTypeState = {
  queryParams: getInitialParamsState<IProductionStandsTypesParams>(
    'productionStandsTypesQueryParams'
  ),
  loading: false,
  error: false,
};

const _productionStandsTypeReducer = createReducer(
  initialState,
  on(ProductionStandsTypesActions.changeQueryParams, (state, action) => ({
    ...state,
    queryParams: { ...action.queryParams },
  }))
);

export function productionStandsTypeReducer(
  state: ProductionStandsTypeState | undefined,
  action: Action
) {
  return _productionStandsTypeReducer(state, action);
}
