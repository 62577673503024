import { Action, createReducer, on } from '@ngrx/store';
import { getInitialParamsState } from '../../../../utils/getInitialStateFromLocalStorage';
import * as LabelsActions from './labels.actions';
import { ILabelsParams } from '../../models/labelsParams';

export interface LabelsState {
  queryParams: ILabelsParams;
  loading: boolean;
  error: boolean;
}

const initialState: LabelsState = {
  queryParams: getInitialParamsState<ILabelsParams>('labelsQueryParams'),
  loading: false,
  error: false,
};

const _labelsReducer = createReducer(
  initialState,
  on(LabelsActions.changeQueryParams, (state, action) => ({
    ...state,
    queryParams: { ...action.queryParams },
  }))
);

export function labelsReducer(state: LabelsState | undefined, action: Action) {
  return _labelsReducer(state, action);
}
