import { Action, createReducer, on } from '@ngrx/store';
import { getInitialParamsState } from '../../../../utils/getInitialStateFromLocalStorage';
import { IPalletsParams } from '../../models/palletsParams';
import * as PalletsActions from './pallets.actions';

export interface PalletsState {
  queryParams: IPalletsParams;
  loading: boolean;
  error: boolean;
}

const initialState: PalletsState = {
  queryParams: getInitialParamsState<IPalletsParams>('palletsQueryParams'),
  loading: false,
  error: false,
};

const _palletsReducer = createReducer(
  initialState,
  on(PalletsActions.changeQueryParams, (state, action) => ({
    ...state,
    queryParams: { ...action.queryParams },
  }))
);

export function palletsReducer(
  state: PalletsState | undefined,
  action: Action
) {
  return _palletsReducer(state, action);
}
